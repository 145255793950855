import { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Layout

const ProtectedLayout = lazy(() => import('./ProtectedLayout.js'));

// Errors

const ForbiddenPage = lazy(() => import('./Pages/ForbiddenPage/ForbiddenPage.js'));

const Error404Page = lazy(() => import('./Pages/Error404Page/Error404Page.js'));

// Страницы админа

const adminRoutes = {
	path: 'admin',
	children: [
		{
			index: true
		},
		{
			path: "account",
			Component: lazy(() => import('./Pages/Admin/AccountPage/AccountPage.js'))
		},
		{
			path: "history",
			Component: lazy(() => import('./Pages/Admin/HistoryPage/HistoryPage.js'))
		}
	]
};

// Страницы ученика

const studentRoutes = {
	path: 'student',
	children: [
		{
			index: true
		},
		{
			path: "account",
			Component: lazy(() => import('./Pages/Student/AccountPage/AccountPage.js'))
		},
		{
			path: 'survey/:id',
			Component: lazy(() => import('./Pages/Student/SurveyPage/SurveyPage.js'))
		},
		{
			path: 'history',
			Component: lazy(() => import('./Pages/Student/HistoryPage/HistoryPage.js'))
		}
	]
};

//

const portalRoutes = {
		path: "portal",
		children: [
			studentRoutes
		]
};

// Router

const router = createBrowserRouter([
	{
		path: "/",
		element: <ProtectedLayout />,
		errorElement: <Error404Page />,
		children: [
			{
				index: true,
				Component: lazy(() => import('./Pages/HomePage/HomePage.js'))
			},
			{
				path: "registration",
				Component: lazy(() => import('./Pages/RegistrationPage/RegistrationPage.js'))
			},
			{
				path: "login",
				Component: lazy(() => import('./Pages/LoginPage/LoginPage.js'))
			},
			portalRoutes
		]
	}
]);

//

function AppRouter() {

  return <RouterProvider router={router} />;

}

export default AppRouter;